
import { useRoute, useRouter } from "vue-router";
import services from "@/services";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  alertController,
  loadingController,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "@vue/runtime-core";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    // const analytics = new Analytics;
    const state = reactive({
      review: "",
      score: 100,
      mission: {
        loading: true,
        data: {
          id: 0,
        },
      },
    });

    const fetch = async (event: any = null) => {
      const id = Number(route.params.id);
      await services.missionFindones(id).then((response) => {
        const { data } = response;
        if (event) {
          if (event.type == "ion-refresh") {
            state.mission.data.id = 0;
          }
        }
        state.mission.data = data;
        state.mission.loading = false;
      });
    };

    const presentAlert = async (message: string, callback: any = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    const onClose = async () => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: `리뷰 작성중..`,
      });
      await loading.present();

      const params = {
        missionId: route.params.id,
        content: state.review,
        score: state.score,
      };
      await services.missionReviewAdd(params).then(
        (response) => {
          console.log(response);
          loading.dismiss();

          if (response.status == 200) {
            // 리뷰 작성기록
            store.commit("review", {
              write: true
            });

            presentAlert("리뷰를 작성해주셔서 감사합니다.", async () => {
              window.history.length > 1 ? router.go(-1) : router.push("/");
            });
          }
        },
        (error) => {
          loading.dismiss();

          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          presentAlert(message);
        }
      );
    };

    // onMounted
    onMounted(() => {
      console.log("onMounted");
      fetch();
      state.review = "";
      state.score = 100;
      // setTimeout(function() {
      //   state.isShow = true;
      // }, 300);
      // analytics.setScreenName(props.screenName);
    });

    return {
      state,
      onClose,
    };
  },
});
